@use "mixins";
@use "variables";

body {
  background-image: url("/assets/img/background.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  position: absolute;
  width: 100%;
}
svg {
  text-rendering: optimizeSpeed
}

html {
  font-size: variables.$font-size-default; /* root font size */
  font-family: variables.$font-default;
  height: 100%;
  color: mixins.theme-var(variables.$color-primary-var);
  touch-action: auto;
  background-color: mixins.theme-var(variables.$color-background-var);
  @include mixins.scrollbar;
  &.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &-ios {
      position: fixed;

      body {
        position: absolute;
        width: 100%;
      }
    }
  }
}

.link {
  color: mixins.theme-var(variables.$color-primary-var);
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;

  &--primary {
    text-decoration: underline;
  }

  &--secondary {
    color: mixins.theme-var(variables.$color-secondary-var);
    border-bottom-color: mixins.theme-var(variables.$color-secondary-var);

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &--gradient {
    @include mixins.textFill();
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
  }
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;

  @include mixins.media-below(variables.$bp-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// BUTTONS
.btn {
  --r: 40px; /* radius */
  --b: 2px; /* border width */
  @include mixins.font(14px, 700);
  text-transform: uppercase;
  background: mixins.theme-var(variables.$color-gradient-var);
  font-family: variables.$font-default;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  border-radius: var(--r);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  position: relative;
  z-index: 0;
  text-decoration: none;
  max-width: 250px;
  cursor: pointer;
  height: 60px;
  transition: .3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--r);
    border: var(--b) solid transparent;
    background: inherit;
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-composite: destination-out;
  }

  &:hover {
    text-shadow: 0 0 0 mixins.theme-var(variables.$color-primary-var);
  }

  &.apple {
    -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;

    &:before {
      -webkit-mask-composite: xor !important;
    }

    &:hover {
      -webkit-text-fill-color: mixins.theme-var(variables.$color-primary-var) !important;
    }
  }

  &.small {
    @include mixins.font(12px, 700);
    height: 40px;
    padding: 15px 40px;
  }

  &.big {
    @include mixins.font(16px, 700);
    padding: 20px 100px;
    height: 70px;
    width: 200px;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.loading {
    pointer-events: none;
    cursor: wait;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  &.gradient {
    background: mixins.theme-var(variables.$color-gradient-var);
    -webkit-background-clip: unset;
    background-clip: unset;
    -webkit-text-fill-color: unset;
    color: mixins.theme-var(variables.$color-background-var);
    border: none;
    transition: .3s all;

    &.apple {
      -webkit-text-fill-color: variables.$color-background !important;

      &:hover {
        -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;
      }
    }

    &:hover {
      background: mixins.theme-var(variables.$color-gradient-var);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;

      &:before {
        content: "";
        background: inherit;
        background-origin: border-box;
        background-clip: border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-composite: destination-out;
      }
    }
  }

  &.animate {
    overflow: hidden;

    &.apple {
      &:hover {
        -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;
      }
    }

    &:hover {
      &:before {
        animation: none;
        filter: none;
        transform: none;
        border-radius: var(--r);
        top: 0;
        width: unset;
        height: unset;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      width: 15px;
      height: 100px;
      -webkit-mask: none;
      background: variables.$color-primary;
      border-radius: unset;
      transform: rotate(30deg);
      filter: blur(5px);
      animation: animatedBackground 3s infinite ease;
    }
  }

  &.simple {
    background: mixins.theme-var(variables.$color-tertiary-var);
    -webkit-background-clip: unset;
    background-clip: unset;
    -webkit-text-fill-color: unset;
    color: mixins.theme-var(variables.$color-secondary-var);
    border: none;

    &:before {
      content: unset;
    }

    &:hover {
      background: mixins.theme-var(variables.$color-secondary-var);
      color: mixins.theme-var(variables.$color-primary-var);
      text-shadow: none;
    }
  }
}

.text-gradient {
  @include mixins.textFill();

  &.apple {
    -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;

    &:before {
      -webkit-mask-composite: xor !important;
    }
  }
}

.btn-attention {
  @include mixins.font(12px, 800);
  text-transform: uppercase;
  border-radius: 22px;
  border: 1px solid mixins.theme-var(variables.$color-error-var);
  background-color: transparent;
  color: mixins.theme-var(variables.$color-error-var);
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

// FORM CONTROL
input[type="password"]::-webkit-caps-lock-indicator {
  content: none;
}

.input {
  outline: none;
  background: transparent;
  color: mixins.theme-var(variables.$color-primary-var);
  border-radius: 0;
  box-sizing: border-box;
  height: 50px;
  border: 1px solid mixins.theme-var(variables.$color-secondary-var);
  width: 100%;

  &:focus{
    background: mixins.theme-var(variables.$color-input-active-var) !important;
    border: 0px !important;
  }
  @include mixins.ngError {
    background: transparent;
    border: 1px solid mixins.theme-var(variables.$color-error-var);
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: mixins.theme-var(variables.$color-gradient-text-var);
    @include mixins.font(14px);
  }

  &--simple {
    @include mixins.font(16px, 600);
    transition: 0.2s;
    padding: 0 30px;
    background-color: transparent;
    border-radius: 42px;
    height: 50px;
    z-index: 1;
    position: relative;
  }

  &--radio {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .radio {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  &--gradient {
    @include mixins.gradientCard();
    -webkit-text-fill-color: unset;
    --r: 50px;
    .form-control__label {
      @include mixins.gradientColor();
    }
    .form-control__error {
      position: absolute;
      color: variables.$color-error-var;
    }

    &:hover, &:focus {
      -webkit-text-fill-color: unset;
    }
  }

}

.text-link {
  @include mixins.textFill();
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:hover {
    color: mixins.theme-var(variables.$color-background-var);
  }
}

.checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 26px;

  &.checkbox--toggle {
    height: 30px;

    input[type='checkbox'] + .checkbox__view {
      animation: none !important;
    }

    input[type='checkbox']:checked + .checkbox__view:after {
      background-color: mixins.theme-var(variables.$color-primary-var);
      left: 33px;
    }

    .checkbox__view {
      min-width: 60px;
      height: 30px;
      border-radius: 20px;
      background-color: transparent;
      border: 1px solid mixins.theme-var(variables.$color-secondary-var);
      position: relative;
      display: block;

      &:after {
        display: block;
        width: 22px;
        height: 22px;
        background-color: mixins.theme-var(variables.$color-secondary-var);
        position: absolute;
        content: '';
        top: 3px;
        left: 3px;
        border-radius: 50%;
        transition: 0.2s;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked {
      & + .checkbox__view {
        will-change: transform;

        &:after {
          background-image: mixins.theme-var(variables.$color-gradient-var);
        }
      }
    }

    &:not(:checked) {
      & + .checkbox__view {
        will-change: transform;
      }
    }
  }

  &__view {
    position: absolute;
    border: 2px solid mixins.theme-var(variables.$color-primary-var);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__label {
    @include mixins.font(14px, 500);
    display: block;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    color: mixins.theme-var(variables.$color-primary-var);
    user-select: none;
  }
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixins.font(14px, 500);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  input {
    position: absolute;
    left: -9999px;
    &:checked + .radio__view {
      &:before {
        background-color: mixins.theme-var(variables.$color-background-var);
        box-shadow: inset 0 0 0 0.4375em mixins.theme-var(variables.$color-secondary-var);
      }
    }
    &:disabled + .radio__view {
      opacity: 0.5;
      pointer-events: none;
      cursor: none;
    }
    &:disabled + .radio__view + .radio__label {
      opacity: 0.5;
      pointer-events: none;
      cursor: none;
    }
    @include mixins.ngError {
      &+.radio__view {
        &:before {
          box-shadow: inset 0 0 0 0.125em mixins.theme-var(variables.$color-error-var);
        }
      }
    }
  }
  &__view {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      transition: 0.25s ease;
      background-color: mixins.theme-var(variables.$color-background-var);
      box-shadow: inset 0 0 0 0.125em mixins.theme-var(variables.$color-secondary-var);
    }
  }
}

.select {
  @include mixins.font(14px, 500);
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: mixins.theme-var(variables.$color-primary-var);

  @include mixins.ngError {
    .select__header {
      border-color: mixins.theme-var(variables.$color-error-var);
    }
  }

  &--secondary {
    .select {
      &__header {
        @include mixins.font(12px, 500);
        background-color: transparent;
        border: 4px solid mixins.theme-var(variables.$color-primary-var);
        text-transform: uppercase;
        color: mixins.theme-var(variables.$color-primary-var);
        border-radius: 42px;

        &:after {
          border-color: mixins.theme-var(variables.$color-secondary-var);
          top: 14px;
          width: 7px;
          height: 7px;
        }
      }
    }

    &.big {
      .select {
        &__header {
          color: #fff;
          height: 60px;
          line-height: 54px;
          padding: 0 75px 0 35px;
          border-radius: 60px;

          &:after {
            top: 23px;
            right: 26px;
          }
        }
      }
    }
  }

  &__header {
    @include mixins.font(15px, 500);
    display: block;
    line-height: 50px;
    height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 0 30px;
    border-radius: 42px;
    border: 1px solid mixins.theme-var(variables.$color-secondary-var);

    &.filled {
      border: none;
      background: mixins.theme-var(variables.$color-tertiary-var);
      color: mixins.theme-var(variables.$color-primary-var);
      height: 35px;
      line-height: 38px;
    }

    .icon-block {
      width: 30px;
      height: 30px;
      right: 8px;
      top: 8px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: mixins.theme-var(variables.$color-tertiary-var);
      border-radius: 50%;

      .icon-arrow {
        transform: rotate(270deg);
        height: 47px;
        @include mixins.font(12px);
      }
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border: 2px solid mixins.theme-var(variables.$color-secondary-var);
      border-top-width: 0;
      border-left-width: 0;
      transform: translateY(-50%) rotateZ(45deg);
      right: 20px;
      top: calc(50% - 2px);
    }
  }

  &__dropdown {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%) scale(0.9, 0);
    width: 100%;
    transition: 0.2s;
    opacity: 0;
    padding: 5px 0;
    border-radius: 8px;
    background-color: mixins.theme-var(variables.$color-tertiary-var);
    overflow: hidden;
    transform-origin: center;
    box-shadow: 0 15px 0 -16px #000;
    z-index: 11;

    &-container {
      overflow: auto;
      max-height: 180px;
      @include mixins.scrollbar();

      &::-webkit-scrollbar {
        background-color: mixins.theme-var(variables.$color-tertiary-var);
      }
    }

    &.open {
      visibility: visible;
      transform: translateY(-50%) scale(1, 1);
      opacity: 1;
      pointer-events: all;
      box-shadow: 0 15px 18px -16px #000;
    }
  }

  &__option {
    @include mixins.font(14px, 600);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 15px;
    transition: 0.1s;
    outline: none;

    &:hover {
      background-color: mixins.theme-var(variables.$color-secondary-var);
    }
  }
}


.text {
  h1 {
    font-size: 18px;
    margin: 0;
  }
  a {
    background: var(--color-gradient);
    background-clip: text;
    color: transparent;
  }
}


.password-toggle {
  &.active {
    color: mixins.theme-var(variables.$color-gradient-text-var);
  }
}


.error-tooltip {
  @include mixins.font(12px, 500);
  color: mixins.theme-var(variables.$color-primary-var);
  position: fixed;
  background-color: mixins.theme-var(variables.$color-background-var);
  border-radius: 6px;
  padding: 15px;
  max-width: 170px;
  user-select: none;
  opacity: 0;
  transition: opacity 0.3s, transform 0.2s;
  z-index: 9999;
  pointer-events: none;

  &:not(.right) {
    transform: translateY(20px);

    &:after {
      left: 20px;
      top: 100%;
    }
  }

  &.right {
    transform: translateX(-20px);
  }


  &.visible {
    transform: scale(1) translateX(0px) translateY(0px);
    opacity: 1;
    pointer-events: all;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: mixins.theme-var(variables.$color-background-var);
    width: 10px;
    height: 10px;
    transform: rotateZ(45deg) translateY(-60%);
    left: -8px;
    top: 50%;
  }

  &--informer {
    &:not(.right) {
      &:after {
        left: auto;
        right: 20px;
        top: 100%;
      }
    }
  }
}

.card {
  background-image: mixins.theme-var(variables.$color-background-gradient-var);
  border-radius: 8px;
  box-shadow: 0px 0px 10px #00240a;
  color: #fff;
}

.card-gradient {
  @include mixins.gradientCard;
}

// Used when disabling global scrolling.
.scrollblock {
  position: fixed;
  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;
  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;
}

.glowing-btn {
  position: relative;
  cursor: pointer;
}

.glowing-btn::after {
  content: '';
  box-shadow: mixins.theme-var(variables.$color-gradient-box-shadow-var);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  filter: blur(5px) brightness(1.1);
  transition: opacity .35s;
  border-radius: 150px;
  animation: opacityLight 2.5s linear both;
  animation-iteration-count: infinite;
}

.glowing-btn:hover:after {
  @include mixins.media-above(variables.$bp-md) {
    animation: stopLight 1.5s forwards;
    transition: all .3s;
    opacity: 0;
    animation-iteration-count: 1;
  }
}

.glowing-icon {
  @extend .glowing-btn;
}

.glowing-icon::after {
  width: 80%;
  filter: blur(9px) brightness(1);
}

@keyframes stopLight {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0;
  }
}

@keyframes opacityLight {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes animatedBackground {
  0% {
    left: 0;
  }
  100% {
    left: 200px;
  }
}
